export default [
  {
    title: 'Avance de Aprendizajes',
    // icon: 'GridIcon',
    // icon: 'PenToolIcon',
    icon: 'EditIcon',
    route: 'avances',
    resource: 'avances',
    action: 'read',
  },
]
