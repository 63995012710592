export default [
  {
    title: 'Informe al Hogar',
    // icon: 'GridIcon',
    // icon: 'PenToolIcon',
    icon: 'FileTextIcon',
    route: 'informehogar',
    resource: 'informehogar',
    action: 'read',
  },
]
