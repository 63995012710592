export default [
  {
    header: 'Mantenedores',
    icon: 'LayersIcon',
    children: [
      {
        title: 'Inquilinos',
        route: 'inquilinos',
        icon: 'DatabaseIcon',
        resource: 'inquilinos',
        action: 'read',
      },
      {
        title: 'Establecimiento',
        route: 'establecimientos',
        icon: 'BellIcon',
        resource: 'establecimientos',
        action: 'read',
      },

      // Alumnos
      {
        title: 'Alumnos',
        route: 'alumnos',
        icon: 'UsersIcon',
        resource: 'alumnos',
        action: 'read',
      },

      // Cursos
      {
        title: 'Cursos',
        route: 'cursos',
        icon: 'BookOpenIcon',
        resource: 'cursos',
        action: 'read',
      },

      // OBJETIVOS
      {
        title: 'Objetivos',
        route: 'objetivos',
        icon: 'ClipboardIcon',
        resource: 'objetivos',
        action: 'read',
      },

      // USUARIOS
      {
        title: 'Usuarios',
        route: 'usuarios',
        icon: 'UserIcon',
        resource: 'usuarios',
        action: 'read',
      },

      // ASIGNATURAS
      {
        title: 'Asignaturas',
        route: 'asignaturas',
        icon: 'KeyIcon',
        resource: 'asignaturas',
        action: 'read',
      },

    ],
  },
]
